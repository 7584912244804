<template>
	<div class="page" :style="height">
		<div class="searchbar">
			<div class="pr10">
				<el-button type="primary" size="small" class="table-toolbar-btn" @click="add"
					v-if="perms.includes('subject:add')"> 新增 </el-button>
				<el-button type="primary" size="small" class="table-toolbar-btn" @click="handleExport"
					v-if="perms.includes('subject:export')"> 导出 </el-button>
				<el-button type="primary" size="small" class="table-toolbar-btn" @click="handleRead('0')"> 未读
				</el-button>
				<el-button type="primary" size="small" class="table-toolbar-btn" @click="handleRead('1')"> 已读
				</el-button>
			</div>
			<el-form ref="form" :model="query" :inline="true" label-width="74px" size="small" :show-message="false"
				class="search-form">
				<el-form-item label="资源">
					<el-select v-model="query.sources" multiple filterable placeholder="请选择资源">
						<el-option v-for="option in sourcesOptions" :key="option.id" :label="option.name"
							:value="option.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="关键字">
					<el-input v-model="query.searchKey" clearable placeholder="请输入关键字"></el-input>
				</el-form-item>
				<!-- <el-form-item label="区域">
					<el-select v-model="query.areaId" filterable clearable placeholder="未选择">
						<el-option v-for="area in areaOptions" :key="area.id" :label="area.name" :value="area.id">
						</el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item label="产品名称">
					<el-cascader
						:options="machineOptions"
						:props="machineProps"
						:show-all-levels="false"
						v-model="query.deviceId"
						filterable
						clearable
						placeholder="请选择产品"
						class="full-width font14">
					</el-cascader>
				</el-form-item>
				<el-form-item label="发件时间">
					<el-date-picker v-model="dateRange" value-format="yyyy-MM-dd" clearable type="daterange"
						start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
				<el-form-item label="内容">
					<el-select v-model="query.details" multiple filterable placeholder="请选择内容">
						<el-option v-for="option in contentOptions" :key="option.id" :label="option.name"
							:value="option.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="主题">
					<el-input v-model="query.searchKey2" clearable placeholder="请输入主题"></el-input>
				</el-form-item>
				<el-form-item label="状态">
					<el-select v-model="query.status" filterable clearable placeholder="全部">
						<el-option v-for="status in statusOptions" :key="status.id" :label="status.name"
							:value="status.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="发件人">
					<el-input v-model="query.pubByLike" clearable placeholder="请输入发件人"></el-input>
				</el-form-item>
				<el-button class="search-btn" @click="getList(1)"></el-button>
				<el-button icon="el-icon-refresh-right" class="refresh-btn" @click="handleReset"></el-button>
			</el-form>
		</div>

		<div class="main-content">
			<el-table :row-class-name="tableRowClassName" :header-cell-style="{color:'#444'}" :data="data" border
				v-loading="loading" :cell-style="getCellStyle" @sort-change="handleSortChange"
				:default-sort="defaultSort">
				<el-table-column prop="orderNo" label="单号" sortable="custom" width="150" show-overflow-tooltip
					align="left" header-align="center">
				</el-table-column>
				<el-table-column prop="title" label="主题" min-width="180" show-overflow-tooltip align="left"
					header-align="center">
					<!-- <template slot-scope="scope">
					<el-link :underline="false" type="primary"> {{ scope.row.title }} </el-link>
				</template> -->
					<template slot-scope="scope">
						<span v-if="scope.row.status === 0" class="content-title pointer" :class="scope.row.readFlag === 0 ? 'fwb' : ''" @click="edit(scope.row)">
							{{ scope.row.title }} </span>
						<span v-else class="content-title pointer" :class="scope.row.readFlag === 0 ? 'fwb' : ''"
							@click="goToDetail(scope.row)"> {{ scope.row.title }} </span>
					</template>
				</el-table-column>
				<el-table-column prop="pubBy" label="发件人" width="80" show-overflow-tooltip align="center"
					header-align="center">
				</el-table-column>
				<el-table-column prop="pubArea" label="区域" width="80" show-overflow-tooltip align="center"
					header-align="center">
				</el-table-column>
				<el-table-column prop="pubTime" label="发件时间" sortable="custom" width="110" show-overflow-tooltip
					align="center" header-align="center">
					<template slot-scope="scope">
						{{ (scope.row.pubTime && $moment(scope.row.pubTime).format('YYYY-MM-DD HH:mm:ss')) || '' }}
					</template>
				</el-table-column>
				<!-- <el-table-column
				prop="receiver"
				label="接收人"
				width="80"
				align="center"
				header-align="center"
			></el-table-column> -->
				<el-table-column prop="deviceNames" label="产品名称" show-overflow-tooltip align="left"
					header-align="center">
				</el-table-column>
				<el-table-column prop="status" label="状态" width="100" align="center" header-align="center">
					<template slot-scope="scope">
						<template v-if="scope.row.status === 0">
							<span> 新建 </span>
						</template>
						<template v-else-if="scope.row.status === 1">
							<span class="warning-text"> 已提交 </span>
						</template>
						<template v-else-if="scope.row.status === 2">
							<span class="success-text"> 进行中 </span>
						</template>
						<template v-else-if="scope.row.status === 3">
							<span class="success-text">
								<template v-if="scope.row.publicFlag === 1">
									已完结(已公开)
								</template>
								<template v-else>
									<span style="color:red;">已完结(仅内部)</span>
								</template>
							</span>
						</template>
						<template v-else>
							<span class="info-text"> 未知 </span>
						</template>
					</template>
				</el-table-column>
				<el-table-column prop="updateTimeStr" label="最后更新时间" sortable="custom" width="128" show-overflow-tooltip
					align="center" header-align="center" class-name="sortable-column"
					label-class-name="sortable-column-header">
					<template slot-scope="scope">
						{{ (scope.row.updateTimeStr && $moment(scope.row.updateTimeStr).format('YYYY-MM-DD HH:mm:ss')) || '' }}
					</template>
				</el-table-column>
				<el-table-column label="操作" width="91" align="center" header-align="center">
					<template slot-scope="scope">
						<div class="table-btn-wrapper">
							<!-- <el-button
							v-if="scope.row.status === 0"
							type="text"
							plain
							class="table-btn info-text"
							@click="release(scope.row)"
						> 发布 </el-button> -->
							<el-button
								v-if="isSuperAdmin || isAdmin || (scope.row.status === 0 && perms.includes('subject:update'))"
								type="text" plain class="table-btn info-text" @click="edit(scope.row)"> 修改 </el-button>
							<el-button v-if="scope.row.status >= 1" type="text" plain class="table-btn info-text"
								@click="goToDetail(scope.row)"> 详细 </el-button>
							<el-button
								v-if="isSuperAdmin || (scope.row.status === 0 && perms.includes('subject:delete'))"
								type="text" plain @click="del(scope.row)" class="table-btn error-text"> 删除 </el-button>
							<el-button
								v-if="(isSuperAdmin || isAdmin) && scope.row.status === 3"
								type="text" plain class="table-btn info-text" @click="reopen(scope.row)"> 重开 </el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>

			<div class="pagination-wrapper pa25">
				<Pagination :page.sync="query.pageNo" :pageSize.sync="query.pageSize" :total="total"
					@sizeChange="handleSizeChange" @currentChange="handleCurrentChange"></Pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import Pagination from '@/components/Pagination'

	export default {
		components: {
			Pagination
		},
		data() {
			return {
				// 权限
				perms: JSON.parse(window.sessionStorage.getItem('perms')),
				// 查询参数
				query: {
					searchKey: '',
					deviceId: '',
					// areaId: '',
					pubByLike: '',
					searchKey2: '',
					pubTimeStart: '',
					pubTimeEnd: '',
					status: '',
					sources: [],
					details: [],
					pageNo: 1,
					pageSize: 20,
					// 排序字段
					fieldType: '',
					// 排序规则
					orderType: '',
					// 已读未读
					readFlag: ''
				},
				defaultSort: {
					prop: '',
					order: ''
				},
				total: 0,
				loading: false,
				dateRange: '',
				// 区域
				areaOptions: [],
				// 状态
				statusOptions: [{
						id: 0,
						name: '新建'
					},
					{
						id: 1,
						name: '已提交'
					},
					{
						id: 2,
						name: '进行中'
					},
					{
						id: 3,
						name: '已完结(已公开)'
					},
					{
						id: 4,
						name: '已完结(仅内部)'
					}
				],
				// 表格数据
				data: [],
				// 资源数据
				sourcesOptions: [],
				// 内容数据
				contentOptions: [],
				// 机种机型数据
				machineOptions: [],
				// 机型选项
				machineProps: {
					multiple: false,
					emitPath: false,
					value: 'id',
					label: 'name',
					children: 'children',
					disabled: 'disabled'
				},
				height: ''
			}
		},
		computed: {
			sortProp() {
				return this.defaultSort.prop
			},
			sortOrder() {
				return this.defaultSort.order
			},
			// 是否为超级管理员
			// 是 才能 (删除或编辑各种状态下的通信箱)
			isSuperAdmin() {
				const userInfoStr = window.sessionStorage.getItem('userInfo')
				if (!userInfoStr) return false

				const userInfo = JSON.parse(userInfoStr)
				if (!userInfo) return false
				if (userInfo.superAdminFlag === '1') return true

				return false
			},
			// 是否是管理员
			// 是 才能 (删除或编辑各种状态下的通信箱)
			isAdmin() {
				const userInfoStr = window.sessionStorage.getItem('userInfo')
				if (!userInfoStr) return false

				const userInfo = JSON.parse(userInfoStr)
				if (!userInfo) return false
				if (userInfo.adminFlag === '1') return true

				return false
			}
		},
		watch: {
			dateRange(range) {
				if (!range || range.length !== 2) {
					this.query.pubTimeStart = ''
					this.query.pubTimeEnd = ''
				} else {
					[this.query.pubTimeStart, this.query.pubTimeEnd] = range
				}
			},
			sortProp(prop) {
				if (prop === 'orderNo') {
					this.query.fieldType = 'order_no'
				} else if (prop === 'pubTime') {
					this.query.fieldType = 'pub_time'
				} else if (prop === 'updateTimeStr') {
					this.query.fieldType = 'update_time'
				} else {
					this.query.fieldType = ''
				}
			},
			sortOrder(order) {
				if (order === 'ascending') {
					this.query.orderType = 'asc'
				} else if (order === 'descending') {
					this.query.orderType = 'desc'
				} else {
					this.query.orderType = ''
				}
			}
		},
		created() {
			// 查询参数复原
			this.query.pageNo = this.$route.params.pageNo || 1
			this.query.searchKey = this.$route.params.searchKey || ''
			// this.query.areaId = this.$route.params.areaId || ''
			this.query.deviceId = this.$route.params.deviceId || ''
			this.query.pubByLike = this.$route.params.pubByLike || ''
			this.query.searchKey2 = this.$route.params.searchKey2 || ''
			if (this.$route.params.pubTimeStart && this.$route.params.pubTimeEnd) {
				this.dateRange = [this.$route.params.pubTimeStart, this.$route.params.pubTimeEnd]
			}
			this.query.pubTimeStart = this.$route.params.pubTimeStart || ''
			this.query.pubTimeEnd = this.$route.params.pubTimeEnd || ''
			if (this.$route.params.status === 0) {
				this.query.status = this.$route.params.status
			} else {
				this.query.status = this.$route.params.status || ''
			}
			// 排序规则复原
			this.defaultSort.prop = this.$route.params.prop || ''
			this.defaultSort.order = this.$route.params.order || ''

			this.query.readFlag = this.$route.params.prop || ''
			this.query.sources = this.$route.params.sources ? JSON.parse(this.$route.params.sources) : []
			this.query.details = this.$route.params.details ? JSON.parse(this.$route.params.details) : []

			this.$nextTick(() => {
				this.getList()
			})
			this.getAreaOptions()
			this.getDictData();
			this.getMachineOptions()
			this.height = 'min-height: ' + (window.innerHeight - 180) + 'px'
		},
		methods: {
			// NO.隔行变色
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (rowIndex % 2 == 1) {
					return 'blue'
				} else {
					return 'yellow'
				}
			},
			// 已读未读
			handleRead(type) {
				this.query.readFlag = type;
				this.getList();
			},
			// 重置查询条件
			handleReset() {
				this.query = {
					searchKey: '',
					// areaId: '',
					deviceId: '',
					pubByLike: '',
					searchKey2: '',
					pubTimeStart: '',
					pubTimeEnd: '',
					status: '',
					sources: [],
					details: [],
					pageNo: 1,
					pageSize: 20,
					// 排序字段
					fieldType: '',
					// 排序规则
					orderType: '',
					// 已读未读
					readFlag: ''
				};
				this.getList();
			},
			// 获取图书馆数据
			getList(page) {
				if (page) this.query.pageNo = page;
				this.loading = true;

				// 处理状态
				// 已完结(已公开) : status=3,publicFlag=1
				// 已完结(仅内部) : status=3,publicFlag=0
				let query = JSON.parse(JSON.stringify(this.query))
				if (this.query.status === 3) {
					query = Object.assign(query, {
						publicFlagMa: 1
					})
				}
				if (this.query.status === 4) {
					query = Object.assign(query, {
						status: 3,
						publicFlagMa: 0
					})
				}

				let that = this;
				that.$request.post(
					"dataSubjectPageList",
					true,
					query,
					function (r) {
						if (r.code == "0") {
							that.data = r.data.list
							that.total = r.data.totalCount
						} else {
							that.$message.error(r.msg)
						}
						that.loading = false
					},
					function () {
						that.loading = false
					}
				)
			},
			// 获取资源,内容,签约类型数据
			getDictData() {
				let that = this
				that.$request.post(
					"getListByCodes",
					true, {
						codes: 'sourceType,contentType'
					},
					function (r) {
						if (r.code == "0") {
							that.sourcesOptions = r.data.sourceType
							that.contentOptions = r.data.contentType
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			},
			// 获取区域数据
			getAreaOptions() {
				let that = this
				that.$request.post(
					"sysDicCodeList",
					true, {
						code: 'areaType'
					},
					function (r) {
						if (r.code == "0") {
							that.areaOptions = r.data
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			},
			// 获取所有机型数据
			getMachineOptions(all) {
				let that = this
				that.$request.post(
					"sysModelDevice",
					false, {},
					function (r) {
						if (r.code == "0") {
							that.machineOptions = r.data
						} else {
							that.$message.error(r.msg);
						}
					}
				)
			},
			// 新增
			add() {
				this.$router.push({
					name: 'mailboxAddEdit',
					params: {
						pageNo: this.query.pageNo,
						searchKey: this.query.searchKey,
						deviceId: this.query.deviceId,
						// areaId: this.query.areaId,
						pubByLike: this.query.pubByLike,
						searchKey2: this.query.searchKey2,
						pubTimeStart: this.query.pubTimeStart,
						pubTimeEnd: this.query.pubTimeEnd,
						status: this.query.status,
						prop: this.defaultSort.prop,
						order: this.defaultSort.order,
						readFlag: this.query.readFlag,
						sources: JSON.stringify(this.query.sources),
						details: JSON.stringify(this.query.details)
					}
				})
			},
			// 编辑
			edit(row) {
				this.$router.push({
					name: 'mailboxAddEdit',
					query: {
						id: row.id
					},
					params: {
						pageNo: this.query.pageNo,
						searchKey: this.query.searchKey,
						deviceId: this.query.deviceId,
						// areaId: this.query.areaId,
						pubByLike: this.query.pubByLike,
						searchKey2: this.query.searchKey2,
						pubTimeStart: this.query.pubTimeStart,
						pubTimeEnd: this.query.pubTimeEnd,
						status: this.query.status,
						prop: this.defaultSort.prop,
						order: this.defaultSort.order,
						readFlag: this.query.readFlag,
						sources: JSON.stringify(this.query.sources),
						details: JSON.stringify(this.query.details)
					}
				})
			},
			// 详情页
			goToDetail(row) {
				this.$router.push({
					name: 'mailboxDetail',
					query: {
						id: row.id
					},
					params: {
						pageNo: this.query.pageNo,
						searchKey: this.query.searchKey,
						deviceId: this.query.deviceId,
						// areaId: this.query.areaId,
						pubByLike: this.query.pubByLike,
						searchKey2: this.query.searchKey2,
						pubTimeStart: this.query.pubTimeStart,
						pubTimeEnd: this.query.pubTimeEnd,
						status: this.query.status,
						prop: this.defaultSort.prop,
						order: this.defaultSort.order,
						readFlag: this.query.readFlag,
						sources: JSON.stringify(this.query.sources),
						details: JSON.stringify(this.query.details)
					}
				})
			},
			// 删除
			del(row) {
				this.$confirm('是否删除当前主题?', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(() => {
					let that = this
					that.$request.post(
						'dataSubjectDelete',
						true, {
							id: row.id
						},
						function (r) {
							if (r.code == "0") {
								that.getList()
								that.$message.success('删除成功')
							} else {
								that.$message.error(r.msg)
							}
						}
					)
				}).catch(() => {})
			},
			handleExport() {
				let that = this
				that.$request.post(
					"dataSubjectExport",
					true,
					that.query,
					function (res) {
						if (res.code == '0') {
							const linkNode = document.createElement('a');
							linkNode.style.display = 'none';
							linkNode.href = res.data;
							document.body.appendChild(linkNode);
							linkNode.click(); //模拟在按钮上的一次鼠标单击
							URL.revokeObjectURL(linkNode.href); // 释放URL 对象
							document.body.removeChild(linkNode);
						} else {
							that.$message.error(res.msg)
						}
					}
				)
			},
			reopen(row) {
				console.log('重新打开: ', row)
				let that = this
				that.$request.post(
					'dataSubjectUpdateStatus',
					true, {
						id: row.id,
						status: "2"
					},
					function (r) {
						if (r.code == "0") {
							that.getList()
							that.$message.success('重开成功')
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			},
			handleSizeChange() {
				this.getList()
			},
			handleCurrentChange() {
				this.getList()
			},
			// 排序规则变更
			handleSortChange({
				prop,
				order
			}) {
				this.defaultSort.prop = prop
				this.defaultSort.order = order

				this.$nextTick(() => {
					this.getList()
				})
			},
			// Safari 中 table 列同时启用 width 和 show-overflow-tip 时
			// el-tooltip 未正确设置 width 行内样式, Safari 不兼容 col 列设置的宽度
			// 内容过长时, 会导致撑开列宽
			getCellStyle({
				column
			}) {
				const tempWidth = column.realWidth || column.width
				if (column.showOverflowTooltip) {
					return {
						minWidth: tempWidth + 'px',
						maxWidth: tempWidth + 'px',
					}
				}
				return {}
			}
		}
	}

</script>

<style lang="scss" scoped>
	.page {
		background-color: white;
	}

	@media screen and (max-width: 1998px) {
		.search-form {
			max-width: 980px;
		}
	}

	@media screen and (max-width: 1113px) {
		.search-form {
			max-width: 444px;
		}
	}

	@media screen and (max-width: 723px) {
		.search-form {
			max-width: 312px;
		}
	}

	.searchbar {

		.el-date-editor--daterange.el-input,
		.el-date-editor--daterange.el-input__inner {
			width: 228px;
		}

		.table-toolbar-btn {
			margin-left: 0;
			margin-right: 10px;
			margin-bottom: 10px;
		}
	}

	.el-table__body {
		width: 100% !important;
		table-layout: fixed !important;
	}

	/deep/ .sortable-column {
		padding-left: 5px !important;
		padding-right: 5px !important;
	}

	/deep/ .sortable-column-header {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.search-btn {
		float: unset !important;
		border: none;
		padding: 0 !important;
		margin: 0 !important;
		margin-left: 10px !important;
		vertical-align: top;
	}

	.refresh-btn {
		width: 32px;
		height: 32px;
		border: none;
		color: #117FFC;
		padding: 0;
		background-color: rgba(17, 127, 252, 0.2);
		font-weight: bold;
		font-size: 22px;
		vertical-align: 8px;
		margin-left: 5px;

		&:hover {
			background-color: rgba(17, 127, 252, 0.3);
		}
	}

</style>
